<template>
    <div class="editpwd">
        <van-form @submit="onSubmit">
            <div class="pwd-list">
                <div class="title">修改账号</div>
                <div class="list">
                    <div class="flex-x-start flex-xy-center">
                        <img src="~assets/images/common/phone.png" alt />
                        <span>账号</span>
                    </div>
                    <van-field v-model="formData.account" name="账号" label="" placeholder="请填写账号"
                        :rules="[{ required: true, message: '请填写账号' }]" />
                </div>
            </div>
            <div style="margin: 16px 0">
                <van-button round block native-type="submit" class="submit-btn">确认修改</van-button>
            </div>
        </van-form>
    </div>
</template>

<script>
import { modifyPwd, modifyAccount } from "api/common";
export default {
    name: "EditPwd",
    data() {
        return {
            formData: {
                account: ""
            },
        };
    },
    mounted() {
        this.formData.account = JSON.parse(localStorage.getItem("payInfo")).account;
    },
    methods: {
        async onSubmit(values) {
            const that = this
            modifyAccount({ account: this.formData.account }).then(res => {
                if (res.code == 200) {
                    this.$toast({
                        type: "success",
                        message: "修改成功",
                        onClose() {
                            that.$router.push("/login");
                        },
                    });
                } else {
                    this.$toast.fail(res.msg);
                }
            })
        },
    },
};
</script>

<style lang="less" scoped>
.editpwd {
    font-size: 14px;
    margin: 15px;

    /deep/.van-field__error-message {
        display: none;
    }

    .pwd-list {
        padding: 0 18px 18px 15px;
        background: #fff;
        border-radius: 4px;

        .title {
            font-size: 16px;
            color: #1e2431;
            padding: 15px 0;
            border-bottom: 1px solid rgba(112, 112, 112, 0.1);
        }

        .list {
            padding: 15px 0;
            border-bottom: 1px solid rgba(112, 112, 112, 0.1);

            p {
                color: rgba(30, 36, 49, 0.6);
            }

            span {
                color: #1e2431;
            }

            img {
                width: 28px;
                height: 28px;
                margin-right: 6px;
            }
        }

        .van-cell {
            padding: 7px 0 0 5px;
        }
    }

    .submit-btn {
        margin-top: 16px;
        height: 36px;
    }
}
</style>
